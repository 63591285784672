import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { usePreview } from "../hooks/usePreview"
import { useTranslation } from "react-i18next"

import { Article } from "../components/article"

function NotFound() {
  const { pathname } = useLocation(),
    { previewData, path } = usePreview(),
    children = [],
    {
      t,
      i18n: { language },
    } = useTranslation()

  // Render previews for unpublished content
  if (pathname === path) {
    if (previewData?.prismicArticle)
      children.push(<Article prismicArticle={previewData.prismicArticle} />)
  }

  return children.length > 0 ? (
    children
  ) : (
    <div
      className="py-24 px-12 xl:px-24 flex flex-col items-center justify-center text-center"
      style={{
        minHeight: `calc(100vh - var(--nav-height) - var(--footer-height))`,
      }}
    >
      <Link to={`/${language}`} className="text-6xl">
        <em>404:</em>
        {` `}
        <span className="not-italic">{t(`error.404`)}</span>
      </Link>
    </div>
  )
}

export default NotFound
